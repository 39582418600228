<template>
    <div class="hospital-content">
        <div class="hospital-banner">
            <img src="https://image.ysxxlm.cn/ysxxlm/pc/static/img/banner-bg@2x.png"/>
        </div>
        <div class="hospital-main">
            <div class="hospital-title">医院频道</div>
            <div class="hospital-desc">为您分享全国各大医院信息、最新学术成果及进展</div>
            <div class="hospital-box">
                <div class="hospital-box-nav">
                    <div class="hospital-box-nav-title" @click="getAllHospital"><img src="https://image.ysxxlm.cn/ysxxlm/pc/static/img/hospital-icon@2x.png"/> 全国医院</div>
                    <div class="hospital-box-nav-item" v-for="(item, index) in region">
                        <span :title="item.label">{{ item.label }}</span><i class="iconfont icon-jiantou"></i>
                        <div class="hospital-box-nav-item-city">
                            <a @click="getCity(item.value, '', item.label)">全部</a>
                            <a v-for="(arr, i) in item.children" @click="getCity(item.value, arr.value, item.label)">{{ arr.label }}</a>
                        </div>
                    </div>
                </div>
                <div class="hospital-box-list">
                    <div class="hospital-box-list-title">
                        <strong>{{ listTitle }}</strong>
                        <i class="iconfont icon-fanhui" @click="getAllHospital" v-if="listTitle != '重点医院推荐'"></i>
                    </div>
                    <div class="hospital-box-list-item" v-for="(item, index) in list">
                        <div class="hospital-box-list-item-title">
                            <div class="hospital-box-list-item-title-name">{{ item.province_name || item.city_name }}</div>
                            <div class="hospital-box-list-item-title-more" @click="getCity(item.province_code, '', item.province_name)" v-if="listTitle == '重点医院推荐'">更多<i class="iconfont icon-jiantou"></i></div>
                        </div>
                        <div class="hospital-box-list-item-main">
                            <span :class="arr.is_tag == 1?'on':''" :title="arr.hospital_name" @click="toDetail(arr.is_tag, arr.hospital_id)" v-for="(arr, i) in item.hospital">{{ arr.hospital_name }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import provinces from '@/commit/utils/province';
    import citys from '@/commit/utils/city';
    export default {
        data() {
            return {
                region: [],
                listTitle: '重点医院推荐',
                list: [],
                isMore: true
            }
        },
        mounted() {
            let region = [];
            provinces.map((item, index) => {
                region.push(
                    {
                        ...item,
                        children: citys[index]
                    }
                )
            })
            this.region = region;
            console.log(this.region)
            this.getAllHospital();
        },
        methods: {
            getAllHospital() {
                this.listTitle = '重点医院推荐';
                let provinceall = [];
                this.region.map(item => {
                    provinceall.push(item.value);
                })
                this.$axios.post(this.apiUrl.hosptial_all, {
                    provinceall
                }).then(res => {
                    this.list = res.data;
                })
            },
            getCity(code, city, label) {
                var timer = setInterval(function(){
                    let osTop = document.documentElement.scrollTop || document.body.scrollTop;
                    let ispeed = Math.floor(-osTop / 5);
                    document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed;
                    if(osTop === 0){
                        clearInterval(timer);
                    }
                },30)

                this.listTitle = label + '全部医院';
                this.$axios.post(this.apiUrl.hosptial_pro, {
                    province_code: code,
                    city_code: city
                }).then(res => {
                    if(res.data.length > 0) {
                        this.list = res.data;
                    }
                    console.log(res)
                })
            },
            toDetail(is_tag, id) {
                if(is_tag != 0) {
                    this.$router.push({
                        path: `/hospitalChannel/${id}/detail`
                    })
                }
            }
        }
    }
</script>

<style scoped>

</style>
